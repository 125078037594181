import { useLocation, useMatches } from "@remix-run/react";
import { useNavigationType } from "@remix-run/react";
import { useEffect } from "react";
import * as Sentry from "@sentry/remix";

// console.debug("Sentry client: ", import.meta.env.MODE);

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://7e9bd1acd842474e93ebd8377f8035b2:21dcc4722fda4ea4a74b03ff72d1074a@o4505311880937472.ingest.sentry.io/4505317126504448",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
});

// Rest of the code - Must be after Sentry.init (v8 documentation)
import posthog from "posthog-js";
import { hydrateRoot } from "react-dom/client";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";

function PosthogInit() {
  useEffect(() => {
    posthog.init("phc_Qybq5VidfEtZdMiiC9VOF6lC8BSbaHW3OUimxe1DHsQ", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only",
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: "*",
      },
      autocapture: false,
      capture_pageview: false, // handled in root.jsx
    });
  }, []);

  return null;
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(
      document,
      <>
        <StrictMode>
          <RemixBrowser />
          <PosthogInit />
        </StrictMode>
      </>
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
